import { PageProps } from 'gatsby';
import { GroupData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/group.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceContactsGroupeCreate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, user, params: { espaceId } }) => {
  const model = new GroupData({
    espaceId,
    params,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form docId={model.getNewDocId()} model={model} type="create" />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceContactsGroupeCreate);
